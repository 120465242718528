<template>
  <!-- 头部的盒子 -->
  <div id="head0">
    <!-- <div class="web-name">{{webName}}</div> -->
    <!-- 日期 时间 -->
    <div class="date-timer">
      <p>
        {{ today }}
      </p>
      <em>{{ weekday }}</em>
      <strong>{{ time }}</strong>
    </div>
    <!-- 安徽安净 天气展示 -->
    <div class="date-weather">
      <div v-if="showWeather">
        <p>
          环境信息：<span>{{ weatherData.city }}</span
          >&nbsp;<span>{{ weatherData.type }}</span>
        </p>
        <p>
          温度:<span>{{ weatherData.wendu }}</span> &nbsp; &nbsp;湿度:<span>{{
            weatherData.shidu
          }}</span>
        </p>
        <p>
          风向:<span>{{ weatherData.fx }}</span> &nbsp;&nbsp; 风力:<span>{{
            weatherData.fl
          }}</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import cloudbase from '@cloudbase/js-sdk'
const app = cloudbase.init({
  env: 'bytube119-1g0lt4yo8ba6465c'
})
export default {
  data () {
    return {
      webName: '',
      today: '',
      weekday: '',
      time: '',
      weatherData: {},
      weatherInfo: 'showDiv' // test 天气信息 初始化为隐藏
    }
  },
  mounted () {
    this.webName = this.$store.state.webData.webName
    this.showWeather = false
    // (this.$store.state.webData.webName === ('北淝河下游沫河口段堤坝白蚁智能监测' || '固镇县白蚁智能监测系统' || '赤卫军指挥部旧址白蚁防治'))
    this.showTime()
    // 显示天气
    this.getWeather()
  },
  methods: {
    // 函数  右上角显示日期和时间
    showTime () {
      var today = new Date()
      var weekday = new Array(7)
      weekday[0] = '星期日'
      weekday[1] = '星期一'
      weekday[2] = '星期二'
      weekday[3] = '星期三'
      weekday[4] = '星期四'
      weekday[5] = '星期五'
      weekday[6] = '星期六'
      var y = today.getFullYear() + '年'
      var month = today.getMonth() + 1 + '月'
      var td = today.getDate() + '日'
      var d = weekday[today.getDay()]
      var h = today.getHours()
      var m = today.getMinutes()
      var s = today.getSeconds()
      if (h < 10) {
        h = '0' + h
      }
      if (m < 10) {
        m = '0' + m
      }
      if (s < 10) {
        s = '0' + s
      }
      this.today = y + month + td
      this.weekday = d
      this.time = h + ':' + m + ':' + s
      setTimeout(() => {
        this.showTime()
      }, 1000)
    },
    // test 获取天气信息
    getWeather (webName) {
      app.callFunction({
        name: 'webLogin',
        data: {
          $url: 'getWeather',
          webName: this.webName
        }
      }).then(res => {
        console.log('getWeather1111111111111111111:', res.result.data)
        this.weatherData = res.result.data.getWeather
        console.log('getWeather22222222222222:', this.weatherData)
        if (this.weatherData.city) {
          this.showWeather = true
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.date-timer {
  position: absolute;
  right: 0.35rem;
  top: 0.3rem;
  line-height: 0.3rem;
  color: #70a1fce7;
  font-size: 0.225rem;
  font-family: "electronicFont";
  text-align: center;
  p {
    font-size: 0.225rem;
    display: block;
  }
  em {
    display: block;
  }
  strong {
    font-size: 0.375rem;
    text-align: left;
    position: absolute;
    left: 0.15rem;
  }
}
// test 天气
.date-weather {
  position: absolute;
  right: 2.1rem;
  top: .35rem;
  line-height: 0.3rem;
  color: #9fbbec79;
  // color: #70a1fce7;
  font-size: .2rem;
  // font-family:'electronicFont';
  // text-align: center;
  p{
    font-size: .2rem;
    display:block;
    span{
      font-weight: bold; /* 或者 700 */
      color: #9fbbeca9;
    }
  }
}
</style>
