<template>
  <div class="com-container">
      <!-- 设备表格 施工图片 -->
    <div class="device">
        <div class="title">
            <h4 @click="showAllDevice()">表.设备运行情况分类汇总</h4>
          <el-tooltip class="item" effect="dark" content="点击左标题显示所有项目" placement="right">
            <i class="el-icon-question el-icon--right"></i>
          </el-tooltip>
          <div class="statistic-text" :class="harmRate >= 8 ? 'statistic-high' : harmRate >= 1 ? 'statistic-mid': harmRate > 0 ? 'statistic-low':'statistic-text'">
            <span v-if="tableList_placed[0].project_name==tableList_placed[tableList_placed.length-1].project_name">项目名称：{{tableList_placed[0].project_name}}</span>
            <span>总投放<a>{{tableList_placed.length+tableList_alerted.length+tableList_handled.length}}</a>个；</span>
            <span>危害率
              <el-tooltip class="item" effect="dark" content="100*（报警+处理）/投放 %" placement="right">
                <i class="el-icon-question el-icon--right"></i>
              </el-tooltip>
              <!-- ：<a>{{(100*(tableList_alerted.length+tableList_handled.length)/(tableList_placed.length+tableList_alerted.length+tableList_handled.length)).toFixed(2)}}%</a></span> -->
              ：<a>{{harmRate}}%</a></span>
            <span>灭治率
              <el-tooltip class="item" effect="dark" content="100*处理/（报警+处理）%" placement="right">
                <i class="el-icon-question el-icon--right"></i>
              </el-tooltip>
              ：<a>{{ isNaN(killerRate) ? '0.00' : killerRate }}%</a></span>
          </div>
          <div class="select-state">
            <span @click="onPlaced"><a>在线</a></span>
            <span @click="onAlerted"><a>报警</a></span>
            <span @click="onHandled"><a>处理</a></span>
          </div>
        </div>
        <!-- 显示表格 [0]在线 [1]报警 [2]处理 -->
        <div class="device-list" v-show="showTable[0]">
          <ul class=" row1">
            <li><span>序号</span>
              <p class="device-num">产品号</p>
              <p class="device-state">设备状态</p>
              <p class="device-project-name">项目名称</p>
              <p class="device-placed-time">投放时间</p>
            </li>
          </ul>
          <ul class=" row2">
            <li v-for="(item,index) in tableList_placed"
                  v-bind:key = " index"
                  @click="showDevice(item)">
              <span>{{index+1}}</span>
              <p class="device-num">{{item.dev_id}}</p>
              <p class="device-state">{{item.state=='placed'?'在线':item.state=='handled'?'处理':"报警"}}</p>
              <p class="device-project-name">{{item.project_name}}</p>
              <p class="device-placed-time">{{item.useTime}}</p>
            </li>
          </ul>
        </div>

        <div class="device-list" v-show="showTable[1]">
          <ul class=" row1">
            <li><span>序号</span>
              <p class="device-num">产品号</p>
              <p class="device-state">设备状态</p>
              <p class="device-project-name">项目名称</p>
              <p class="device-placed-time">投放时间</p>
              <p class="device-alerted-time">报警时间</p>
            </li>
          </ul>
          <ul class=" row2">
            <li v-for="(item,index) in tableList_alerted"
                  v-bind:key = " index"
                  @click="showDevice(item)">
              <span>{{index+1}}</span>
              <p class="device-num">{{item.dev_id}}</p>
              <p class="device-state">{{item.state=='placed'?'在线':item.state=='handled'?'处理':"报警"}}</p>
              <p class="device-project-name">{{item.project_name}}</p>
              <p class="device-placed-time">{{item.useTime}}</p>
              <p class="device-alerted-time">{{item.alerted_times}}</p>
            </li>
          </ul>
        </div>

        <div class="device-list" v-show="showTable[2]">
          <ul class=" row1">
            <li><span>序号</span>
              <p class="device-num">产品号</p>
              <p class="device-state">设备状态</p>
              <p class="device-project-name">项目名称</p>
              <p class="device-placed-time">投放时间</p>
              <p class="device-alerted-time">报警时间</p>
              <p class="device-handled-time">处理时间</p>
            </li>
          </ul>
          <ul class=" row2">
            <li v-for="(item,index) in tableList_handled"
                  v-bind:key = " index"
                  @click="showDevice(item)">
              <span>{{index+1}}</span>
              <p class="device-num">{{item.dev_id}}</p>
              <p class="device-state">{{item.state=='placed'?'在线':item.state=='handled'?'处理':"报警"}}</p>
              <p class="device-project-name">{{item.project_name}}</p>
              <p class="device-placed-time">{{item.useTime}}</p>
              <p class="device-alerted-time">{{item.alerted_times}}</p>
              <p class="device-handled-time">{{item.handled_time}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script>
// import cloudbase from '@cloudbase/js-sdk'
// const app = cloudbase.init({
//   env: 'bytube119-1g0lt4yo8ba6465c'
// })

export default {
  data () {
    return {
      webData: null,
      chartInstance: null,
      tableList_placed: [],
      tableList_alerted: [],
      tableList_handled: [],
      // percent_alerted: 0,
      // percent_handled: 0,
      projectNameFromPie: null, // pie中点击后产生的项目名称
      showTable: [1, 0, 0]
    }
  },
  mounted () {
    this.initData()
  },
  computed: {
    // 计算属性的危害率 harmRate
    harmRate: function () {
      return (100 * (this.tableList_alerted.length + this.tableList_handled.length) / (this.tableList_placed.length + this.tableList_alerted.length + this.tableList_handled.length)).toFixed(2)
    },
    // 计算属性的灭治率 harmRate
    killerRate: function () {
      return (100 * this.tableList_handled.length / (this.tableList_alerted.length + this.tableList_handled.length)).toFixed(2)
    }
  },
  methods: {
    initData () {
      this.tableList_placed = this.$store.state.webData.mapData_placed.map(item => (
        {
          dev_id: item.dev_id,
          state: item.state,
          project_name: item.Project[0].name,
          project_area: item.Project[0].area,
          useTime: this.formatDate(item.Form_Use[0].time),
          projectDevId: item.Form_Use[0].projectDevId,
          img: item.Form_Use[0].img,
          TMapGeoCenter: item.Form_Use[0].location
        }
      ))
      this.tableList_alerted = this.$store.state.webData.mapData_alerted.map(item => (
        {
          dev_id: item.dev_id,
          state: item.state,
          project_name: item.Project[0].name,
          project_area: item.Project[0].area,
          useTime: this.formatDate(item.Form_Use[0].time),
          projectDevId: item.Form_Use[0].projectDevId,
          img: item.Form_Use[0].img,
          TMapGeoCenter: item.Form_Use[0].location,
          alerted_times: this.formatDate(item.alerted_times[0])
        }
      ))
      this.tableList_handled = this.$store.state.webData.mapData_handled.map(item => (
        {
          dev_id: item.dev_id,
          state: item.state,
          project_name: item.Project[0].name,
          project_area: item.Project[0].area,
          useTime: this.formatDate(item.Form_Use[0].time),
          projectDevId: item.Form_Use[0].projectDevId,
          img: item.Form_Use[0].img,
          TMapGeoCenter: item.Form_Use[0].location,
          alerted_times: this.formatDate(item.alerted_times[0]),
          handled_time: this.formatDate(item.Form_Handled[0].time)
        }
      ))
      // 地图中心点经纬度
      // console.log('this.tableList_placed.TMapGeoCenter', this.tableList_placed[0].TMapGeoCenter)
      this.$store.commit('newGeoCenter', this.tableList_placed[0].TMapGeoCenter)
    },
    formatDate (datetime) {
      if (!datetime) {
        return
      }
      const dl = datetime.toString().length
      if (dl === 10) { datetime = datetime * 1000 }
      var date = new Date(datetime)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var year = date.getFullYear()
      var month = ('0' + (date.getMonth() + 1)).slice(-2)
      var sdate = ('0' + date.getDate()).slice(-2)
      var hour = ('0' + date.getHours()).slice(-2)
      var minute = ('0' + date.getMinutes()).slice(-2)
      // var second = ('0' + date.getSeconds()).slice(-2)
      // 拼接
      var result = year + '-' + month + '-' + sdate + ' ' + hour + ':' + minute
      // var result = year + "-"+ month +"-"+ sdate +" "+ hour +":"+ minute +":" + second;
      // 返回
      return result
    },
    onPlaced () {
      this.showTable = [1, 0, 0]
    },
    onAlerted () {
      // console.log('aaaaaaaaaaa')
      this.showTable = [0, 1, 0]
    },
    onHandled () {
      // console.log('hhhhhhhhhhhhhhh')
      this.showTable = [0, 0, 1]
    },
    // 点击产品号，详情显示
    showDevice (devObj) {
      // console.log('dev_id:', devObj.dev_id)
      // console.log('dev_id:', devObj.TMapGeoCenter)
      this.$store.commit('detailDevId', devObj.dev_id) // 提交数据到vuex
      this.$store.commit('newGeoCenter', devObj.TMapGeoCenter)
    },
    showAllDevice () {
      // console.log('all device is show')
      this.initData()
    }
  },
  watch: {
    '$store.state.webData' () {
      this.initData() // 初始化表格数据
    },
    '$store.state.projectNameFromPie' () {
      var projectName = this.$store.state.projectNameFromPie
      // console.log('state 变化了p', projectName)

      // 筛选只含项目简称为 projectName 的数据
      this.tableList_placed = this.$store.state.webData.mapData_placed.map(item => (
        {
          dev_id: item.dev_id,
          state: item.state,
          project_name: item.Project[0].name,
          project_shortName: item.Project[0].shortName,
          project_area: item.Project[0].area,
          useTime: this.formatDate(item.Form_Use[0].time),
          projectDevId: item.Form_Use[0].projectDevId,
          img: item.Form_Use[0].img,
          TMapGeoCenter: item.Form_Use[0].location
        }
      )).filter((item) => {
        return item.project_shortName === projectName
      })

      this.tableList_alerted = this.$store.state.webData.mapData_alerted.map(item => (
        {
          dev_id: item.dev_id,
          state: item.state,
          project_name: item.Project[0].name,
          project_shortName: item.Project[0].shortName,
          project_area: item.Project[0].area,
          useTime: this.formatDate(item.Form_Use[0].time),
          projectDevId: item.Form_Use[0].projectDevId,
          img: item.Form_Use[0].img,
          TMapGeoCenter: item.Form_Use[0].location,
          alerted_times: this.formatDate(item.alerted_times[0])
        }
      )).filter((item) => {
        return item.project_shortName === projectName
      })

      this.tableList_handled = this.$store.state.webData.mapData_handled.map(item => (
        {
          dev_id: item.dev_id,
          state: item.state,
          project_name: item.Project[0].name,
          project_shortName: item.Project[0].shortName,
          project_area: item.Project[0].area,
          useTime: this.formatDate(item.Form_Use[0].time),
          projectDevId: item.Form_Use[0].projectDevId,
          img: item.Form_Use[0].img,
          TMapGeoCenter: item.Form_Use[0].location,
          alerted_times: this.formatDate(item.alerted_times[0]),
          handled_time: this.formatDate(item.Form_Handled[0].time)
        }
      )).filter((item) => {
        return item.project_shortName === projectName
      })
      this.$store.commit('newGeoCenter', this.tableList_placed[0].TMapGeoCenter)
      // console.log('newMapDataPlaced', this.tableList_placed) // 新的在线数据
      // console.log('newMapDataAlerted', this.tableList_alerted) // 新的报警数据
      // console.log('newMapDataHandled', this.tableList_handled) // 新的处理数据
    }
  }
}
</script>

<style lang="less" scoped>
    .device {
        background: rgba(101, 132, 226, 0.3);
        padding: .125rem;
        margin-top: .25rem;
        height: 3.625rem;
        position: relative;
        width: 100%;
        border: 1px solid rgba(25, 186, 139, 0.17);
        text-align: center;
        color: white;
        z-index: 1010;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          width: 30px;
          height: 10px;
          border-top: 2px solid #02a6b5;
          border-left: 2px solid #02a6b5;
        }
        &::after {
          position: absolute;
          bottom: 0;
          right: 0;
          content: "";
          width: 30px;
          height: 10px;
          border-right: 2px solid #02a6b5;
          border-bottom: 2px solid #02a6b5;
        }
          .title{
            // font-size: .225rem;
            // text-align: right;
            color: #fff;
            margin-right: .5rem;
            // right: .25rem;
            margin-bottom: .1rem;
            display: flex;
            h3{
              flex: 1;
            }
            .statistic-text{
              flex: 7;
              text-align: left;
              margin-left: .25rem;
              span{
                margin-left: .25rem;
              }
              .a{
                color: rgb(204, 18, 18);
                text-decoration: none;
                margin: 0 0.125rem;
              }
            }
            .statistic-low{
              background-color: yellow;
              color: green
            }
            .statistic-mid{
              background-color: orange;
              color: yellow;
            }
            .statistic-high{
              background-color: red;
              color: yellow;
            }
            .select-state{
              flex: 2;
              text-align: right;
              right: .125rem;
              margin-left: .25rem;
              span{
                margin-left: .25rem;
              }
              a{
                // color: rgb(204, 18, 18);
                text-decoration: none;
                // margin: 0 0.125rem;
              }
            }

          }

        .device-list {
          // width: 2.5rem;
          width: 100%;
          height: 90%;
          border: 1px solid #0e94ea;
          background-color: rgba(42, 50, 56, 0.712);
          overflow-x:hidden;
          color: #70a1fce7;

          li {
            width: 100%;
            height: 0.3rem;
            line-height: 0.3rem;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-around;
            font-size: 0.18rem;
            span {
              width: 0;
              flex-grow: 1;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .device-num{
              width: 0;
              flex-grow: 2;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

            }
            .device-state{
              width: 0;
              flex-grow: 2;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .device-project-name{
              width: 0;
              flex-grow: 8;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .device-placed-time{
              width: 0;
              flex-grow: 5;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .device-alerted-time{
              width: 0;
              flex-grow: 5;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .device-handled-time{
              width: 0;
              flex-grow: 5;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          li:nth-child(2n) {
            background: rgba(3, 44, 71, 0.6);
          }
        }
        .row1{
          height: 0.4rem;
          padding-top: .0625rem;
          line-height: 0.4rem;
          font-size: 0.2rem;
          background: rgba(1, 17, 27, 0.8);
          border-bottom: 1px solid #0e94ea;
          // p {
          //   color: white;
          // }
          }
        .row2{
          // height: 0.4rem;
          // padding-top: .0625rem;
          // line-height: 0.4rem;
          // font-size: 0.2rem;
          // background: rgba(1, 17, 27, 0.8);
          border-top: 0;
          // p {
          //   color: white;
          // }
          }
    }
</style>
