<template>
  <div class="com-container">
    <h3 >报警数统计
      <el-tooltip v-if="showReport" class="item" effect="dark" content="双击问号下载当月统计报表" placement="right">
      <i  class="el-icon-question el-icon--right" @dblclick="downLoadReport()"></i>
    </el-tooltip>
    </h3>
    <div class="com-chart" id="line"></div>
  </div>
</template>
<script>
// 基础示例 按需引入
import * as echarts from 'echarts/core'
import {
  GridComponent
} from 'echarts/components'
import {
  LineChart
} from 'echarts/charts'
import {
  CanvasRenderer
} from 'echarts/renderers'
import cloudbase from '@cloudbase/js-sdk'
const app = cloudbase.init({
  env: 'bytube119-1g0lt4yo8ba6465c'
})
echarts.use(
  [GridComponent, LineChart, CanvasRenderer]
)

export default {
  data () {
    return {
      webData: null,
      myChart: null,
      lineXData: [],
      lineYData: [],
      showReport: false // 当项目是南沙xxx时显示下载report为true
    }
  },
  mounted () {
    this.lineXData = this.$store.state.webData.lineXData
    this.lineYData = this.$store.state.webData.lineYData
    this.showReport = (this.$store.state.webData.webName === '南沙水利设施养护项目')
    // this.lineXData = ['09', '08', '07']
    // this.lineYData = [0, 1, 0]
    this.initChart()
  },
  methods: {

    initChart () {
      var chartDom = document.getElementById('line')
      this.myChart = echarts.init(chartDom)

      // window.onresize = this.myChart.resize
      var option = {
        tooltip: {
          trigger: 'axis',
          formatter: '{b}月份<br/> {a} {c} 处'
        },
        grid: {
          left: '10',
          top: '30',
          right: '10',
          bottom: '10',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            // x轴更换数据
            data: this.lineXData,
            // 文本颜色为rgba(255,255,255,.6)  文字大小为 12
            axisLabel: {
              textStyle: {
                color: 'rgba(255,255,255,.6)',
                fontSize: 12
              }
            },
            // x轴线的颜色为   rgba(255,255,255,.2)
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,.2)'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisTick: { show: false },
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,.1)'
              }
            },
            minInterval: 1,
            axisLabel: {
              textStyle: {
                color: 'rgba(255,255,255,.6)',
                fontSize: 12
              }
            },
            // 修改分割线的颜色
            splitLine: {
              lineStyle: {
                color: 'rgba(255,255,255,.1)'
              }
            }
          }
        ],
        series: [
          {
            name: '白蚁预警',
            type: 'line',
            smooth: true,
            lineStyle: {
              normal: {
                color: '#00d887',
                width: 2
              }
            },
            // areaStyle: {
            //   normal: {
            //     color: new this.myChart.graphic.LinearGradient(
            //       0,
            //       0,
            //       0,
            //       1,
            //       [
            //         {
            //           offset: 0,
            //           color: 'rgba(0, 216, 135, 0.4)'
            //         },
            //         {
            //           offset: 0.8,
            //           color: 'rgba(0, 216, 135, 0.1)'
            //         }
            //       ],
            //       false
            //     ),
            //     shadowColor: 'rgba(0, 0, 0, 0.1)'
            //   }
            // },
            // 设置拐点 小圆点
            symbol: 'circle',
            // 拐点大小
            symbolSize: 5,
            // 设置拐点颜色以及边框
            itemStyle: {
              color: '#00d887',
              borderColor: 'rgba(221, 220, 107, .1)',
              borderWidth: 12
            },
            // 开始不显示拐点， 鼠标经过显示
            showSymbol: false,
            data: this.lineYData
          }
        ]
      }
      option && this.myChart.setOption(option)
    },
    // 双击月曲线的标题 自动生成月报告并下载docx文档
    downLoadReport () {
      console.log('object')
      app.callFunction({
        name: 'webApp',
        data: {
          $url: 'reportdoc',
          total_placed: this.$store.state.webData.total_placed,
          total_alerted: this.$store.state.webData.total_alerted,
          total_handled: this.$store.state.webData.total_handled
        }
      }).then(res => {
        console.log('reportUrl:', res.result.data.reportUrl)
        const fileUrl = res.result.data.reportUrl

        // 创建一个临时的<a>标签
        const a = document.createElement('a')
        a.href = fileUrl
        // a.download = '南沙11.docx' // 设置下载的文件名

        // 触发点击事件以下载文件
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
    }
  },
  watch: {
    '$store.state.webData' () {
      this.lineXData = this.$store.state.webData.lineXData
      this.lineYData = this.$store.state.webData.lineYData
      this.showReport = this.$store.state.webData.webName === '南沙水利设施养护项目'
      this.initChart()
    }
  }
}
</script>
<style lang="less" scoped>
</style>
